import { api } from './api';

export const validateToken = async (token: string, campaignId?: string) => {
    try {
        const response = await api.post(
            "/prize-management/winners/validateAccessToken",
            { campaignId },
            {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": token,
                },
            }
        );

        return response.data;
    } catch (error: any) {
        throw error.response?.data ?? { message: "An unexpected error occurred" };
    }
};