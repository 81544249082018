import { api } from './api';
import { type Win } from '../../interfaces/Win';
import tokenService from "../../auth/TokenService";

interface RequestBody {
  searchTerm: string
  campaignId: string
  perPage: number
  pageNumber: number
  sortBy: string
  order: 'ASC' | 'DESC'
}

export const searchWinners = async (data: RequestBody) => {
  try {
    const token = tokenService.getSearchWinnerToken();

    const response = await api.post(
        "/prize-management/winners/search",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        }
    );
    return response.data;
  } catch (error: any) {
    throw error.response?.data ?? { message: "Error during search request" };
  }
};

export const resendPrizeCode = async (data: { uuid: string, campaignId: string }) => {
  return await api.post('/prize-management/winners/resend-code', data)
    .then((result) => result);
};

export const redeemPrizeCode = async (data: { playId: string, campaignId: string }) => {
  return await api.post('/prize-management/winners/redeem-prize', data)
    .then((result) => result);
};

export const getPrizes = async (campaignId: string) => {
  return await api.get(`/prize-management/winners/${campaignId}/prizes`)
    .then((result) => result);
};

export const generateWin = async (data: Win) => {
  return await api.post('/prize-management/winners/generate-win', data)
    .then((result) => result);
};
