interface cookieConfigType {
  brame_at: string
  refresh_brame_at: string
  search_winner_token: string
}

export const cookieConfig: cookieConfigType = {
  brame_at: 'brame_at',
  refresh_brame_at: 'refresh_brame_at',
  search_winner_token: "search_winner_token"
};
