import Cookies from "js-cookie";
import { cookieConfig } from "./authConfig";

class TokenService {
  getCookieAccessToken() {
    return Cookies.get(cookieConfig.brame_at) ?? "";
  }

  getCookieRefreshToken() {
    return Cookies.get(cookieConfig.refresh_brame_at);
  }

  updateCookieAccessToken(token: string) {
    Cookies.set(cookieConfig.brame_at, token);
  }

  updateCookieRefreshToken(token: string) {
    Cookies.set(cookieConfig.refresh_brame_at, token);
  }

  deleteCookieAccessToken() {
    Cookies.remove(cookieConfig.brame_at);
  }

  deleteCookieRefreshToken() {
    Cookies.remove(cookieConfig.refresh_brame_at);
  }

  getSearchWinnerToken() {
    return Cookies.get(cookieConfig.search_winner_token) ?? "";
  }

  updateSearchWinnerToken(token: string) {
    Cookies.set(cookieConfig.search_winner_token, token, { expires: 7, secure: true });
  }

  deleteSearchWinnerToken() {
    Cookies.remove(cookieConfig.search_winner_token);
  }
}

const tokenService = new TokenService();
export default tokenService;
