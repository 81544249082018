import React from "react";
import './WinnerAccessOverlay.scss';
import BrameLogo from "../../../assets/images/brame-logo-grey.svg";

interface TokenOverlayProps {
  token: string;
  setToken: (value: string) => void;
  handleTokenSubmit: (e: React.FormEvent) => void;
  error: string;
}

const WinnerAccessOverlay: React.FC<TokenOverlayProps> = ({
 token,
 setToken,
 handleTokenSubmit,
 error,
 }) => {
  return (
      <div className="overlay">
          <div className="overlay-content">
              <img src={BrameLogo} alt="Brame Logo"/>
              <h3>Enter the token below to search for the winner.</h3>
              <form onSubmit={handleTokenSubmit}>
                  <p>Token</p>
                  <input
                      type="text"
                      value={token}
                      onChange={(e) => setToken(e.target.value)}
                      placeholder="Enter token..."
                      required
                      className={error ? "error-input" : ""}
                  />
                  {error && <p className="error">{error}</p>}
                  <button type="submit">Submit</button>
              </form>
          </div>
      </div>
  );
};

export default WinnerAccessOverlay;